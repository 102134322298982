import React, {useState, useEffect} from "react"
import {useLocation, Link} from "react-router-dom"
import cursoService from "../../services/curso.service"
import MyLayout from "../../components/MyLayout"
import {
  Breadcrumb,
  Col,
  Row,
  Collapse,
  Space,
  Table,
  Tooltip,
  Modal,
  Button,
  message,
  Spin,
  Avatar,
  Form,
  Input,
} from "antd"
import moment from "moment"
import {
  LogoutOutlined,
  LineChartOutlined,
  CheckCircleOutlined,
  UserOutlined,
  DeleteOutlined,
  EditOutlined,
  RetweetOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"
import RetiroModal from "../../components/modals/RetiroModal"
import SearchEstudianteModal from "../../components/modals/SearchEstudianteModal"
import matriculaService from "../../services/matricula.service"
import ArchivoCursoModal from "../../components/modals/ArchivoCursoModal"
import {PDFViewer} from "@react-pdf/renderer"
import CursoEstudiantesPDF from "../PDF/CursoEstudiantesPDF"
import {useNavigate} from "react-router-dom"
import archivo_cursoService from "../../services/archivo_curso.service"
import {useAuth} from "../../context/auth.context"
import seguimiento_actividadService from "../../services/seguimiento_actividad.service"
import SeguimientoActividadEditModal from "../../components/modals/Edit/SeguimientoActividadEditModal"
import SeguimientoActividadModal from "../../components/modals/SeguimientoActividadModal"
import MatriculaEditModal from "../../components/modals/Edit/MatriculaEditModal"
import evaluacionEstudiante from "../../services/evaluacion_estudiante.service"
import MatriculaExamenModal from "../../components/modals/MatriculaExamenModal"
import calendarioService from "../../services/calendario.service"
import ArchivoCursoEditModal from "../../components/modals/Edit/ArchivoCursoEditModal"
import "./VerCurso.css"
import {useForm} from "rc-field-form"
const {Panel} = Collapse
const {confirm} = Modal
const VerCurso = () => {
  let location = useLocation()
  let myCurso = location.state.curso
  const [curso, setCurso] = useState(null)
  const [estudiantes, setEstudiantes] = useState([])
  const [estudiantesActivos, setEstudiantesActivos] = useState([])
  const [archivos, setArchivos] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewHistorialModal, setViewHistorialModal] = useState(false)
  const [matriculaSelected, setMatriculaSelected] = useState()
  const [viewEstudianteModal, setViewEstudianteModal] = useState(false)
  const [viewArchivoModal, setViewArchivoModal] = useState(false)
  const [viewPDF, setViewPDF] = useState(false)
  const [viewSeguimientoModal, setViewSeguimientoModal] = useState(false)
  const [seguimientos, setSeguimientos] = useState([])
  const [viewEditSeguimiento, setViewEditSeguimiento] = useState(false)
  const [seguimientoSelected, setSeguimientoSelected] = useState([])
  const [viewTrasladoModal, setViewTrasladoModal] = useState(false)
  const [viewModalExamenInternacional, setViewModalExamenInternacional] =
    useState(false)
  const [estudianteSelected, setEstudianteSelected] = useState()
  const [tipoExamenSelected, setTipoExamenSelected] = useState()
  const [viewEditFechaMatricula, setViewEditFechaMatricula] = useState(false)
  const [viewEditFechaRetiro, setViewEditFechaRetiro] = useState(false)
  const [viewModalObservacion, setViewModalObservacion] = useState(false)
  const [histoMatricula, setHistoMatricula] = useState()
  const {user, role} = useAuth()
  const [notas, setNotas] = useState([])
  const [formFecha] = Form.useForm()
  const [formFechaRetiro] = Form.useForm()
  const [clases, setClases] = useState([])
  const [viewRedirectEstudianteModal, setViewRedirectEstudianteModal] =
    useState(false)
  const [estudianteRetiroSelected, setEstudianteRetiroSelected] = useState()
  const [viewArchivoEditModal, setViewArchivoEditModal] = useState(false)
  const [archivoSelected, setArchivoSelected] = useState()
  const [viewModalRechazo, setViewModalRechazo] = useState(false)
  const [motivoRechazo, setMotivoRechazo] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    getCurso()
    getSeguimientos()
    getNotas()
  }, [])

  const getCurso = () => {
    setLoading(true)
    cursoService
      .get(myCurso.id)
      .then((response) => {
        let filterCurso = response.data[0]
        console.log(response.data[0])
        setCurso(filterCurso)
        setEstudiantes(filterCurso.Matriculas)
        let filtroActivos = filterCurso.Matriculas.filter(
          (item) => item.estado == "Activo"
        )
        setEstudiantesActivos(filtroActivos)
        setArchivos(filterCurso.ArchivoCursos)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const getNotas = () => {
    evaluacionEstudiante.getForain(myCurso.id).then((res) => {
      setNotas(res.data)
    })
  }

  const getSeguimientos = () => {
    calendarioService.getForain(myCurso.id).then((res) => {
      let misClases = res.data
      setClases(misClases)

      seguimiento_actividadService.getForain(myCurso.id).then((res) => {
        let seguimientos = res.data
        let listaAllSeguimientos = []

        for (let item of misClases) {
          let fechaHorario = moment(item.start).format("YYYY-MM-DD")
          let buscar = seguimientos.filter((seg) => seg.fecha == fechaHorario)
          if (buscar.length > 0) {
            listaAllSeguimientos.push(buscar[0])
          } else {
            listaAllSeguimientos.push({
              fecha: fechaHorario,
              objetivo: "No registra",
              habilidad: "No registra",
              actividades: "No registra",
              observaciones: "No registra",
              createdAt: "No registra",
            })
          }
        }
        console.log(listaAllSeguimientos)
        setSeguimientos(listaAllSeguimientos)
      })
    })
  }

  const editSeguimiento = (record) => {
    setViewEditSeguimiento(true)
    setSeguimientoSelected(record)
  }

  const deleteSeguimiento = (record) => {
    confirm({
      title: "Desea eliminar esta actividad?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        seguimiento_actividadService
          .delete(record.id)
          .then((response) => {
            console.log(response.data)

            getSeguimientos()
            message.success("Actividad Eliminada")
          })
          .catch((e) => {
            console.log(e)
            message.error("Error al eliminar")
          })
      },
    })
  }

  const formatName = (record) => {
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${
      record.primerApellido
    } ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const openModalFecha = (record) => {
    setMatriculaSelected(record)
    setViewEditFechaMatricula(true)

    formFecha.setFieldsValue({
      fecha: moment(record.createdAt).format("YYYY-MM-DD"),
    })
  }

  const reactivarEstudiante = (record) => {
    setMatriculaSelected(record)
    confirm({
      title: "Desea reactivar estudiante?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true)
        matriculaService
          .reactive(record.id)
          .then((res) => {
            message.success("Estudiante Activo")
            getCurso()
            setLoading(false)
          })
          .catch((error) => {
            message.error("Ha ocurrido un error al reactivar")
            setLoading(false)
          })
      },
    })
  }

  const openModalRetiro = (record) => {
    setMatriculaSelected(record)
    setViewEditFechaRetiro(true)

    formFechaRetiro.setFieldsValue({
      fecha: moment(record.fechaRetiro).format("YYYY-MM-DD"),
    })
  }

  const validarHorario = (record) => {
    if (record.horaInicial) {
      let cadenaHorario = ""
      let dias = JSON.parse(record.diasHorario)
      for (let item of dias) {
        cadenaHorario += item.substr(0, 3) + ", "
      }

      cadenaHorario +=
        "- " +
        moment(record.fechaInicial + " " + record.horaInicial).format(
          "hh:mm a"
        ) +
        " a " +
        moment(record.fechaInicial + " " + record.horaFinal).format("hh:mm a")

      return cadenaHorario
    } else {
      return "No hay calendario asignado"
    }
  }

  const calcularPromedio = (id, notas, parametros) => {
    //console.log(parametros)
    let notasEstudiante = notas.filter((item) => item.estudiante_id == id)
    let totalPromedio = 0
    for (let param of parametros) {
      let totalSub = 0
      let totalParam = 0
      for (let sub of notasEstudiante) {
        if (param.id == sub.parametro_id) {
          totalSub += sub.valor * (sub.SubparametroCurso.porcentaje / 100)
          // console.log(sub.valor * (sub.SubparametroCurso.porcentaje/100))
        }
      }
      totalParam = totalSub * (param.porcentaje / 100)
      totalPromedio += totalParam
    }

    return totalPromedio.toFixed(1)
  }

  const viewHistomodal = (record) => {
    setViewHistorialModal(true)
    setMatriculaSelected(record.id)
    setEstudianteRetiroSelected(record.Estudiante)
  }

  const viewModalTraslado = (record) => {
    setViewTrasladoModal(true)
    setMatriculaSelected(record.id)
  }

  const calculateAge = (dateString) => {
    return moment().diff(dateString, "years", true).toFixed(0)
  }

  const handleModalObservacion = (record) => {
    if (record.estado == "Retirado") {
      setMatriculaSelected(record)

      setViewModalObservacion(true)
      if (record.Histomatriculas.length > 0) {
        setHistoMatricula(record.Histomatriculas[0])
      }
    }
  }

  const columns = [
    {
      title: " ",
      render: (record) => {
        if (record.Estudiante.url != null) {
          return (
            <Avatar
              size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64}}
              src={record.Estudiante.url}
            />
          )
        } else {
          return (
            <Avatar
              size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64}}
              icon={<UserOutlined />}
            />
          )
        }
      },
    },
    {
      title: "Nombres",
      render: (record) => (
        <Link to={"/ver-estudiante"} state={{estudiante: record.Estudiante}}>
          {formatName(record.Estudiante)}
        </Link>
      ),
    },

    {
      title: "No. Identificación",
      render: (record) => <div>{record.Estudiante.numId}</div>,
    },

    {
      title: "Edad",
      render: (record) => {
        const formats = ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"]
        const date = moment(record.Estudiante.fechaNacimiento, formats, true) // Intenta parsear con los formatos
        console.log(date)
        return <div>{calculateAge(date)}</div>
      },
    },

    {
      title: "Fecha de Matricula",
      render: (record) => (
        <div>{moment(record.createdAt).format("DD-MM-YYYY")}</div>
      ),
    },

    {
      title: "Promedio",
      render: (record) => (
        <div>
          {calcularPromedio(record.Estudiante.id, notas, curso.ParametroCursos)}
        </div>
      ),
    },

    {
      title: "Estado",
      render: (record) => (
        <div
          className={record.estado == "Retirado" ? "retirado" : "activo"}
          onClick={() => {
            handleModalObservacion(record)
          }}
        >
          {record.estado}
        </div>
      ),
    },

    {
      title: "Opciones",
      render: (record) => {
        if (
          record.estado == "Activo" &&
          (role == "Superadministrador" ||
            role == "Administrativo" ||
            role == "Seguimiento" ||
            role == "Cumplimiento")
        ) {
          return (
            <>
              <Tooltip title="Retirar">
                <span
                  className="btn_option"
                  onClick={() => viewHistomodal(record)}
                >
                  <LogoutOutlined />
                </span>
              </Tooltip>

              <Tooltip title="Trasladar a otro curso">
                <span
                  className="btn_option"
                  onClick={() => viewModalTraslado(record)}
                >
                  <RetweetOutlined />
                </span>
              </Tooltip>

              <Tooltip title="Actualizar Fecha de matricula">
                <span
                  className="btn_option"
                  onClick={() => openModalFecha(record)}
                >
                  <CalendarOutlined />
                </span>
              </Tooltip>
            </>
          )
        } else if (record.estado == "Retirado") {
          return (
            <>
              <Tooltip title="Actualizar Fecha de retiro">
                <span
                  className="btn_option"
                  onClick={() => openModalRetiro(record)}
                >
                  <CalendarOutlined />
                </span>
              </Tooltip>

              <Tooltip title="Actualizar Fecha de matricula">
                <span
                  className="btn_option"
                  onClick={() => openModalFecha(record)}
                >
                  <CalendarOutlined />
                </span>
              </Tooltip>

              <Tooltip title="Reactivar Estudiante">
                <span
                  className="btn_option"
                  onClick={() => reactivarEstudiante(record)}
                >
                  <CheckCircleOutlined />
                </span>
              </Tooltip>
            </>
          )
        }
      },
    },
  ]

  const descargar = (url) => {
    window.open(url)
  }

  const eliminarArchivo = (record) => {
    confirm({
      title: "Desea eliminar el archivo del curso?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true)
        archivo_cursoService
          .delete(record.id, user.id)
          .then((res) => {
            message.success("Archivo Eliminado")
            getCurso()
            setLoading(false)
          })
          .catch((error) => {
            message.error("Ha ocurrido un error al eliminar")
            setLoading(false)
          })
      },
    })
  }

  const validarSemana = (record) => {
    let semanasActivas = calcularSemanas(
      myCurso.fechaInicial,
      myCurso.fechaFinal
    )
    console.log("Semanas activas", semanasActivas)
    let filtro = semanasActivas.filter(
      (item) =>
        item.nombre == "Semana " + record.semana && item.disabled == false
    )
    console.log(filtro)
    if (filtro.length > 0) {
      return true
    } else {
      return false
    }
  }

  const obtenerMartesDeSemana = (numeroSemana, año) => {
    const fechaMartes = moment().year(año).week(numeroSemana).day(3)
    return fechaMartes.format("YYYY-MM-DD") // Cambia el formato de fecha si lo deseas
  }

  const obtenerViernesDeSemana = (numeroSemana, año) => {
    const fechaMartes = moment().year(año).week(numeroSemana).day(4)
    return fechaMartes.format("YYYY-MM-DD") // Cambia el formato de fecha si lo deseas
  }

  const compararFechas = (fecha1, fecha2) => {
    return fecha1.isBefore(fecha2) // Compara si fecha1 es antes que fecha2
  }

  const calcularSemanas = (fechaInicial, fechaFinal) => {
    let fechaInicialCurso = moment(fechaInicial, "YYYY-MM-DD HH:mm:ss")
    let fechaFinalCurso = moment(fechaFinal, "YYYY-MM-DD 23:59:59")
    let todasFechas = []
    let todasSemanas = []

    while (fechaInicialCurso.isSameOrBefore(fechaFinalCurso)) {
      todasFechas.push(fechaInicialCurso.format("YYYY-MM-DD"))
      fechaInicialCurso.add(1, "days")
    }

    let semanaHoy = moment().week()
    let diaHoy = moment().day()

    for (let item of todasFechas) {
      let fecha = moment(item)
      let numSemana = fecha.week()
      //var numSemana = item.week();
      todasSemanas.push(numSemana)
    }

    const arraySinRepetidos = todasSemanas.filter((elem, index) => {
      return todasSemanas.indexOf(elem) === index
    })

    let data = []

    if (role != "Superadministrador") {
      for (let i = 0; i < arraySinRepetidos.length; i++) {
        const semana = arraySinRepetidos[i]
        let disabled = true

        if (curso.jornada != "Sábados") {
          if (
            semana > semanaHoy ||
            (semana === semanaHoy &&
              compararFechas(
                moment(),
                obtenerMartesDeSemana(semanaHoy + 1, moment().format("YYYY"))
              ))
          ) {
            disabled = false
          }
        } else {
          if (
            semana > semanaHoy ||
            (semana === semanaHoy &&
              compararFechas(
                moment(),
                obtenerViernesDeSemana(semanaHoy + 1, moment().format("YYYY"))
              ))
          ) {
            disabled = false
          }
        }

        data.push({
          id: i + 1,
          nombre: "Semana " + (i + 1),
          semana: semana,
          disabled: disabled,
        })
      }
    } else {
      for (let i = 0; i < arraySinRepetidos.length; i++) {
        const semana = arraySinRepetidos[i]
        data.push({
          id: i + 1,
          nombre: "Semana " + (i + 1),
          semana: semana,
          disabled: false,
        })
      }
    }

    return data
  }

  const editarSemana = (record) => {
    setViewArchivoEditModal(true)
    setArchivoSelected(record)
  }

  const aprobarArchivo = (record) => {
    confirm({
      title: "Desea aprobar el archivo?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        archivo_cursoService
          .status(record.id, {estado: "Aprobado"})
          .then((res) => {
            console.log(res)
            message.success("Archivo Aprobado")
            getCurso()
          })
          .catch((error) => {
            console.log(error)
            message.error("Error al aprobar")
          })
      },
    })
  }

  const rechazarArchivo = (record) => {
    setViewModalRechazo(true)
    setArchivoSelected(record)
  }

  const enviarRechazo = () => {
    archivo_cursoService
      .status(archivoSelected.id, {
        estado: "Rechazado",
        motivo_rechazo: motivoRechazo,
      })
      .then((res) => {
        console.log(res)
        message.success("Archivo Rechazado")
        setViewModalRechazo(false)
        getCurso()
      })
      .catch((error) => {
        console.log(error)
        message.error("Error al rechazar")
      })
  }

  const convertirFecha = (fecha) => {
    let array = fecha.split("-")
    let myfecha = array[2] + "/" + array[1] + "/" + array[0]
    return myfecha
  }

  const columnsArchivo = [
    {
      title: "Tipo",
      render: (record) => <div>{record.tipo}</div>,
    },

    {
      title: "Semana",
      render: (record) => <div>Semana {record.semana} </div>,
    },

    {
      title: "Observaciones",
      render: (record) => <div>{record.observaciones}</div>,
    },

    {
      title: "Estado",
      render: (record) => (
        <div
          className={`${record.estado == "Cargado" ? "activo" : ""} ${
            record.estado == "Aprobado" ? "finalizado" : ""
          }  ${record.estado == "Rechazado" ? "retirado" : ""}`}
        >
          <Tooltip title={record.motivo_rechazo}> {record.estado}</Tooltip>
        </div>
      ),
    },

    {
      title: "Descargar Aqui",
      render: (record) => (
        <Button
          onClick={() => {
            descargar(record.url)
          }}
        >
          Descargar
        </Button>
      ),
    },

    {
      title: "Fecha de subida",

      render: (record) => {
        let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a")
        return <div>{myFecha}</div>
      },
    },

    {
      title: "Opciones",
      render: (record) => {
        if (
          (record.estado == "Cargado" && role == "Docente") ||
          (record.estado == "Cargado" && role == "Superadministrador") ||
          (record.estado == "Cargado" && role == "Coordinador Académico")
        ) {
          return (
            <div>
              {(role == "Superadministrador" ||
                role == "Coordinador Académico") && (
                <>
                  <Tooltip title="Aprobar Archivo">
                    <Button
                      type="success"
                      onClick={() => aprobarArchivo(record)}
                    >
                      <CheckCircleOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Rechazar Archivo">
                    <Button
                      type="success"
                      onClick={() => rechazarArchivo(record)}
                    >
                      <CloseCircleOutlined />
                    </Button>
                  </Tooltip>
                </>
              )}

              {validarSemana(record) && (
                <Button type="primary" onClick={() => editarSemana(record)}>
                  <EditOutlined />
                </Button>
              )}
              <Button type="danger" onClick={() => eliminarArchivo(record)}>
                <DeleteOutlined />
              </Button>
            </div>
          )
        }

        if (
          (record.estado == "Aprobado" && role == "Docente") ||
          (record.estado == "Aprobado" && role == "Superadministrador") ||
          (record.estado == "Aprobado" && role == "Coordinador Académico")
        ) {
          return (
            <div>
              {(role == "Superadministrador" ||
                role == "Coordinador Académico") && <></>}

              {validarSemana(record) && (
                <Button type="primary" onClick={() => editarSemana(record)}>
                  <EditOutlined />
                </Button>
              )}
              <Button type="danger" onClick={() => eliminarArchivo(record)}>
                <DeleteOutlined />
              </Button>
            </div>
          )
        }

        if (
          (record.estado == "Rechazado" && role == "Docente") ||
          (record.estado == "Rechazado" && role == "Superadministrador") ||
          (record.estado == "Rechazado" && role == "Coordinador Académico")
        ) {
          return (
            <div>
              {(role == "Superadministrador" ||
                role == "Coordinador Académico") && <></>}

              {validarSemana(record) && (
                <Button type="primary" onClick={() => editarSemana(record)}>
                  <EditOutlined />
                </Button>
              )}
              <Button type="danger" onClick={() => eliminarArchivo(record)}>
                <DeleteOutlined />
              </Button>
            </div>
          )
        }
      },
    },
  ]

  const columnsSeguimiento = [
    {
      title: "Fecha",
      render: (record) => <div>{convertirFecha(record.fecha)}</div>,
    },

    {
      title: "Objetivo",
      render: (record) => <div className="textScroll">{record.objetivo}</div>,
    },

    {
      title: "Habilidad",
      render: (record) => <div className="textScroll">{record.habilidad}</div>,
    },

    {
      title: "Actividades Realizadas",
      render: (record) => (
        <div className="textScroll">{record.actividades}</div>
      ),
    },

    {
      title: "Observaciones",
      render: (record) => (
        <div className="textScroll">{record.observaciones}</div>
      ),
    },

    {
      title: "Fecha Registro",
      render: (record) => {
        if (record.objetivo != "No registra") {
          return <div>{moment(record.createdAt).format("DD-MM-YYYY")}</div>
        }
      },
    },

    {
      title: "Opciones",
      render: (record) => {
        if (
          role == "Docente" ||
          (role == "Superadministrador" && record.objetivo != "No registra")
        ) {
          return (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  editSeguimiento(record)
                }}
              >
                <EditOutlined />
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  deleteSeguimiento(record)
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          )
        }
      },
    },
  ]

  const searchEstudiante = () => {
    setViewEstudianteModal(true)
  }

  const viewLista = () => {
    setViewPDF(true)
  }

  const viewEvaluaciones = (curso) => {
    navigate("/ver-evaluacion", {state: {curso: curso}})
  }

  const viewAsistencias = (curso) => {
    navigate("/ver-inasistencia", {state: {curso: curso}})
  }

  const regresar = () => {
    navigate(-1)
  }

  const viewModalSeguimiento = () => {
    setViewSeguimientoModal(true)
  }

  const actualizarFecha = (values) => {
    let form = {}
    form.fecha = values.fecha + " 23:59:59"
    matriculaService
      .updateDate(matriculaSelected.id, form)
      .then((res) => {
        console.log(res)
        message.success("Actualizado Correctamente")
        getCurso()
        setViewEditFechaMatricula(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const irEstudiante = () => {
    navigate("/ver-estudiante", {state: {estudiante: estudianteRetiroSelected}})
  }

  const actualizarFechaRetiro = (values) => {
    let form = {}
    form.fecha = values.fecha + " 23:59:59"
    matriculaService
      .updateDateRetiro(matriculaSelected.id, form)
      .then((res) => {
        console.log(res)
        message.success("Actualizado Correctamente")
        getCurso()
        setViewEditFechaRetiro(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const saveMatricula = (form) => {
    setEstudianteSelected(form.estudiante_id)
    setTipoExamenSelected(form.nombreExamen)
    setLoading(true)
    matriculaService
      .create(form)
      .then((response) => {
        console.log(response.data)
        setLoading(false)
        setViewEstudianteModal(false)
        getCurso()
        message.success("Guardado Correctamente")
      })
      .catch((e) => {
        setLoading(false)
        message.error("No se ha podido guardar")
        console.log(e)
      })
  }

  const subirArchivo = () => {
    setViewArchivoModal(true)
  }

  const validarTipo = (tipo) => {
    if (tipo == "Niños") {
      return "text-center bg-purple-500 rounded px-1"
    } else if (tipo == "Adolescentes") {
      return " text-center bg-yellow-500 rounded px-1"
    } else if (tipo == "Adultos") {
      return "text-center bg-green-500 rounded px-1"
    }
  }

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <MyLayout>
      <Button type="primary" onClick={regresar}>
        Regresar
      </Button>

      <Breadcrumb style={{margin: "16px 0"}}>
        <Breadcrumb.Item>Académico</Breadcrumb.Item>
        <Breadcrumb.Item>Curso </Breadcrumb.Item>
      </Breadcrumb>

      {curso && (
        <>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div className="flex gap-4 items-center ">
                    <div className="nombre">
                      {curso.curso} {curso.nivel} {curso.ciclo}
                    </div>
                    <div>
                      <strong>Docente:</strong> {curso.Docente.primerNombre}
                      {curso.Docente.primerApellido}
                    </div>
                    <div className={`${validarTipo(curso.tipo_publico)}`}>
                      {curso.tipo_publico}
                    </div>
                  </div>
                </Col>
                <Col span={12} className="columna">
                  <div>
                    <strong>Codigo: </strong> {curso.id}
                  </div>
                  <div>
                    <strong>Fecha Inicial: </strong>
                    {moment(curso.fechaInicial).format("DD-MM-YYYY")}
                  </div>
                  <div>
                    <strong>Periodo: </strong> {curso.periodo}
                  </div>
                  <div>
                    <strong>Estado: </strong> {curso.estado}
                  </div>
                  <div>
                    <strong>Horario de clase: </strong> {validarHorario(curso)}
                  </div>

                  {(curso.modo == "Curso" ||
                    (curso.modo == "Entrenamiento" &&
                      curso.ciclo == "APTIS")) && (
                    <Tooltip title="Ver evaluaciones">
                      <Button
                        onClick={() => {
                          viewEvaluaciones(curso)
                        }}
                        type="primary"
                      >
                        Ver evaluaciones <LineChartOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </Col>

                <Col span={12} className="columna">
                  <div>
                    <strong>Programa: </strong> {curso.idioma}
                  </div>
                  <div>
                    <strong>Fecha Final: </strong>
                    {moment(curso.fechaFinal).format("DD-MM-YYYY")}
                  </div>
                  <div>
                    <strong>Jornada: </strong> {curso.jornada}
                  </div>
                  <div>
                    <strong>Fecha Creación: </strong>
                    {moment(curso.createdAt).format("DD-MM-YYYY")}
                  </div>
                  <div>
                    <strong>Modo: </strong> {curso.presentacion}
                  </div>

                  <Tooltip title="Ver inasistencias">
                    <Button
                      onClick={() => {
                        viewAsistencias(curso)
                      }}
                      type="primary"
                    >
                      Ver Inasistencias <CheckCircleOutlined />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Space direction="vertical" className="space">
                <Collapse collapsible="header" defaultActiveKey={["1"]}>
                  <Panel header="Estudiantes" key="1">
                    {curso.estado == "Activo" &&
                      (role == "Superadministrador" ||
                        role == "Administrativo" ||
                        role == "Seguimiento" ||
                        role == "Cumplimiento") && (
                        <Button
                          className="btn_actions"
                          type="primary"
                          onClick={searchEstudiante}
                        >
                          Agregar Estudiante
                        </Button>
                      )}

                    <Button className="btn_actions" onClick={viewLista}>
                      Lista Estudiantes
                    </Button>

                    {estudiantes && (
                      <Table dataSource={estudiantes} columns={columns}></Table>
                    )}
                  </Panel>

                  <Panel header="Archivos del curso" key="2">
                    {(role == "Superadministrador" || role == "Docente") && (
                      <Button type="primary" onClick={subirArchivo}>
                        Subir Archivo
                      </Button>
                    )}

                    {archivos && (
                      <Table
                        dataSource={archivos}
                        columns={columnsArchivo}
                      ></Table>
                    )}
                  </Panel>

                  <Panel header="Seguimiento de actividades" key="3">
                    {(role == "Superadministrador" || role == "Docente") && (
                      <Button type="primary" onClick={viewModalSeguimiento}>
                        Crear Seguimiento
                      </Button>
                    )}

                    <Table
                      columns={columnsSeguimiento}
                      dataSource={seguimientos}
                      rowClassName={(record) =>
                        record.objetivo != "No registra"
                          ? "disabled-row"
                          : "red-row"
                      }
                    ></Table>
                  </Panel>
                </Collapse>
              </Space>
            </Col>
          </Row>
        </>
      )}
      {matriculaSelected && viewHistorialModal && (
        <RetiroModal
          matricula_id={matriculaSelected}
          updateLoading={setLoading}
          visible={viewHistorialModal}
          modo={myCurso.modo}
          updateVisible={setViewHistorialModal}
          updateCurso={getCurso}
          modalRedirigir={() => {
            setViewRedirectEstudianteModal(true)
          }}
        ></RetiroModal>
      )}

      {viewEstudianteModal && (
        <SearchEstudianteModal
          totalMatriculas={estudiantesActivos.length}
          updateInternacional={setViewModalExamenInternacional}
          updateVisible={setViewEstudianteModal}
          saveMatricula={saveMatricula}
          modo={myCurso.modo}
          curso_id={myCurso.id}
          visible={viewEstudianteModal}
        >
          {myCurso}
        </SearchEstudianteModal>
      )}

      {viewArchivoModal && (
        <ArchivoCursoModal
          visible={viewArchivoModal}
          updateLoading={setLoading}
          updateVisible={setViewArchivoModal}
          updateCurso={getCurso}
        >
          {myCurso}
        </ArchivoCursoModal>
      )}

      {viewArchivoEditModal && (
        <ArchivoCursoEditModal
          visible={viewArchivoEditModal}
          dataArchivo={archivoSelected}
          updateLoading={setLoading}
          updateVisible={setViewArchivoEditModal}
          updateCurso={getCurso}
        >
          {myCurso}
        </ArchivoCursoEditModal>
      )}

      {viewPDF && estudiantes && (
        <Modal
          visible={viewPDF}
          width={1080}
          onCancel={() => {
            setViewPDF(false)
          }}
        >
          <PDFViewer style={{width: "100%", height: "90vh"}}>
            <CursoEstudiantesPDF curso={curso}>
              {estudiantes}
            </CursoEstudiantesPDF>
          </PDFViewer>
        </Modal>
      )}

      {viewSeguimientoModal && (
        <SeguimientoActividadModal
          visible={viewSeguimientoModal}
          updateVisible={setViewSeguimientoModal}
          curso_id={curso.id}
          updateLoading={setLoading}
          updateSeguimientos={getSeguimientos}
        ></SeguimientoActividadModal>
      )}

      {viewEditSeguimiento && (
        <SeguimientoActividadEditModal
          visible={viewEditSeguimiento}
          updateVisible={setViewEditSeguimiento}
          updateSeguimientos={getSeguimientos}
        >
          {seguimientoSelected}
        </SeguimientoActividadEditModal>
      )}

      {viewTrasladoModal && (
        <MatriculaEditModal
          visible={viewTrasladoModal}
          updateVisible={setViewTrasladoModal}
          updateLoading={setLoading}
          matricula_id={matriculaSelected}
          updateCurso={getCurso}
        ></MatriculaEditModal>
      )}

      {viewModalExamenInternacional && estudianteSelected && (
        <MatriculaExamenModal
          visible={viewModalExamenInternacional}
          estudiante_id={estudianteSelected}
          updateVisible={setViewModalExamenInternacional}
          tipoExamen={tipoExamenSelected}
        ></MatriculaExamenModal>
      )}

      {viewEditFechaMatricula && (
        <Modal
          title="Actualizar fecha de Matricula"
          visible={viewEditFechaMatricula}
          onCancel={() => {
            setViewEditFechaMatricula(false)
          }}
          onOk={formFecha.submit}
        >
          <Form layout="vertical" form={formFecha} onFinish={actualizarFecha}>
            <Form.Item label="Fecha de Matricula" name="fecha">
              <Input type="date"></Input>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {viewEditFechaRetiro && (
        <Modal
          title="Actualizar fecha de Retiro"
          visible={viewEditFechaRetiro}
          onCancel={() => {
            setViewEditFechaRetiro(false)
          }}
          onOk={formFechaRetiro.submit}
        >
          <Form
            layout="vertical"
            form={formFechaRetiro}
            onFinish={actualizarFechaRetiro}
          >
            <Form.Item label="Fecha de Retiro" name="fecha">
              <Input type="date"></Input>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {viewModalObservacion && histoMatricula && matriculaSelected && (
        <Modal
          title="Motivo del retiro"
          visible={viewModalObservacion}
          onCancel={() => {
            setViewModalObservacion(false)
          }}
          onOk={() => {
            setViewModalObservacion(false)
          }}
          okText="Aceptar"
          cancelText="Cancelar"
        >
          <div>
            <strong>Comentario: </strong>
            {histoMatricula.comentario}
          </div>
          <div>
            <strong>Fecha Retiro: </strong>
            {moment(matriculaSelected.fechaRetiro).format("DD/MM/YYYY")}
          </div>
        </Modal>
      )}

      {viewRedirectEstudianteModal && (
        <Modal
          visible={viewRedirectEstudianteModal}
          title="Ver Estudiante"
          onOk={irEstudiante}
          onCancel={() => {
            setViewRedirectEstudianteModal(false)
          }}
          okText="ir a Estudiante"
        >
          El estudiante tiene obligaciones finacieras pendientes, desea ir al
          perfil del estudiante?
        </Modal>
      )}

      {viewModalRechazo && (
        <Modal
          title="Motivo del rechazo"
          visible={viewModalRechazo}
          onCancel={() => {
            setViewModalRechazo(false)
          }}
          onOk={() => {
            enviarRechazo()
          }}
          okText="Aceptar"
          cancelText="Cancelar"
        >
          <div>
            <strong>Comentario: </strong>
            <Form.Item
              placeholder="Comentario"
              name="comentario"
              required
              value={motivoRechazo}
              onChange={(e) => setMotivoRechazo(e.target.value)}
            >
              <Input type="text"></Input>
            </Form.Item>
          </div>
        </Modal>
      )}
    </MyLayout>
  )
}

export default VerCurso
