import "./App.css"
import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom"
import Home from "./pages/Home/Home"
import Usuario from "./pages/Usuario/Usuario"
import Workgroup from "./pages/Workgroup/Workgroup"
import Embudo from "./pages/Embudo/Embudo"
import Medio from "./pages/Medio/Medio"
import Canal from "./pages/Canal/Canal"
import Pregunta from "./pages/Pregunta/Pregunta"
import Leads from "./pages/Leads/Leads"
import VerLead from "./pages/VerLead/VerLead"
import Estudiante from "./pages/Estudiante/Estudiante"
import VerEstudiante from "./pages/VerEstudiante/VerEstudiante"
import Jornada from "./pages/Jornada/Jornada"
import Docente from "./pages/Docente/Docente"
import VerDocente from "./pages/VerDocente/VerDocente"
import Nivel from "./pages/Nivel/Nivel"
import Periodo from "./pages/Periodo/Periodo"
import Curso from "./pages/Curso/Curso"
import VerCurso from "./pages/VerCurso/VerCurso"
import VerCalendario from "./pages/VerCalendario/VerCalendario"
import VerEvaluacion from "./pages/VerEvaluacion/VerEvaluacion"
import VerInasistencia from "./pages/VerInasistencia/VerInasistencia"
import Examen from "./pages/Examen/Examen"
import VerExamen from "./pages/VerExamen/VerExamen"
import Tarifa from "./pages/Tarifa/Tarifa"
import Tutoria from "./pages/Tutoria/Tutoria"
import Notificacion from "./pages/Notificacion/Notificacion"
import Carga from "./pages/Carga/Carga"
import TarifaDocente from "./pages/TarifaDocente/TarifaDocente"
import PagoDocente from "./pages/PagoDocente/PagoDocente"
import Login from "./pages/Login/Login"
import {AuthProvider} from "./context/auth.context"
import {ProtectedRoute} from "./routes/ProtectedRoute"
import Perfil from "./pages/VistaDocente/Perfil"
import Cursos from "./pages/VistaDocente/Cursos"
import Reportes from "./pages/Reportes/Reportes"
import Tarea from "./pages/Tarea/Tarea"
import PasswordPage from "./pages/Password/PasswordPage"
import ReporteIngresos from "./pages/Reportes/ReporteIngresos"
import VerCursoDocente from "./pages/VistaDocente/VerCursoDocente"
import Constancia from "./pages/Constancia/Constancia"
import HistorialAcademico from "./pages/HistorialAcademico/HistorialAcademico"
import CursosEstudiante from "./pages/VistaEstudiante/CursosEstudiante"
import PerfilEstudiante from "./pages/VistaEstudiante/PerfilEstudiante"
import Denegado from "./pages/denegado/Denegado"
import ImprimirLiquidacion from "./pages/PagoDocente/ImprimirLiquidacion"
import Mejoramiento from "./pages/Mejoramiento/Mejoramiento"
import HistorialSeguimiento from "./pages/HistorialSeguimiento/HistorialSeguimiento"
import OOPT from "./pages/Formularios/OOPT"
import Formulario from "./pages/Formularios/Formulario"
import APTIS from "./pages/Formularios/APTIS"
import TOEFL from "./pages/Formularios/TOEFL"
import IELTS from "./pages/Formularios/IELTS"
import ReporteAcademico from "./pages/Reportes/ReporteAcademico"
import ReporteEstudiantes from "./pages/Reportes/ReporteEstudiantes"
import ReporteConstancia from "./pages/ReporteConstancia/ReporteConstancia"
import Padre from "./components/Biblioteca/Padre"
import Hijo from "./components/Biblioteca/Hijo"
import ConfigBiblioteca from "./pages/ConfigBiblioteca/ConfigBiblioteca"
import ReviewBiblioteca from "./pages/ReviewBiblioteca/ReviewBiblioteca"
import ReporteSaldo from "./pages/Reportes/ReporteSaldo"
import DerechoGrado from "./pages/DerechoGrado/DerechoGrado"

function App() {
  return (
    <HashRouter>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Docente, Estudiante, Coordinador Académico, Cumplimiento, Seguimiento, Marketing",
                ]}
              >
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/usuario"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Usuario />
              </ProtectedRoute>
            }
          />
          <Route
            path="/workgroup"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Workgroup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/medio"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Medio />
              </ProtectedRoute>
            }
          />
          <Route
            path="/canal"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Canal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pregunta"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Pregunta />
              </ProtectedRoute>
            }
          />
          <Route
            path="/embudo"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Embudo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Leads />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estudiantes"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Estudiante />
              </ProtectedRoute>
            }
          />
          <Route
            path="/docentes"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Docente />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jornadas"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Jornada />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-lead"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <VerLead />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-estudiante"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Coordinador Académico",
                ]}
              >
                <VerEstudiante />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-docente"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Coordinador Académico",
                ]}
              >
                <VerDocente />
              </ProtectedRoute>
            }
          />
          <Route
            path="/niveles"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Nivel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/periodos"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Periodo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cursos"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Curso />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-curso"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <VerCurso />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-calendario"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <VerCalendario />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-evaluacion"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                ]}
              >
                <VerEvaluacion />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-inasistencia"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                ]}
              >
                <VerInasistencia />
              </ProtectedRoute>
            }
          />
          <Route
            path="/examenes"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Examen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-examen"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <VerExamen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tarifas"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Tarifa />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tutorias"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Tutoria />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notificaciones"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                ]}
              >
                <Notificacion />
              </ProtectedRoute>
            }
          />
          <Route
            path="/carga"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Carga />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tarifa/docente"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <TarifaDocente />
              </ProtectedRoute>
            }
          />
          <Route
            path="/liquidacion/docente"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <PagoDocente />
              </ProtectedRoute>
            }
          />
          <Route
            path="/liquidacion/docente/imprimir"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <ImprimirLiquidacion />
              </ProtectedRoute>
            }
          />

          <Route
            path="/perfil/docente"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                ]}
              >
                <Perfil />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cursos/docente"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                ]}
              >
                <Cursos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-curso/docente"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                ]}
              >
                <VerCursoDocente />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tareas"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Tarea />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reporte-cuadre"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Reportes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/password"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                  "Estudiante",
                ]}
              >
                <PasswordPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reporte-ingresos"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <ReporteIngresos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/constancias"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <Constancia />
              </ProtectedRoute>
            }
          />

          <Route
            path="/historial-academico"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                  "Docente",
                ]}
              >
                <HistorialAcademico />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cursos/estudiante"
            element={
              <ProtectedRoute rol={["Estudiante"]}>
                <CursosEstudiante />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perfil/estudiante"
            element={
              <ProtectedRoute rol={["Estudiante"]}>
                <PerfilEstudiante />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mejoramiento"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Docente, Seguimiento",
                ]}
              >
                <Mejoramiento />
              </ProtectedRoute>
            }
          />
          <Route
            path="/acceso-denegado"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador, Administrativo, Cumplimiento, Seguimiento, Docente, Estudiante, Coordinador Académico",
                ]}
              >
                <Denegado />
              </ProtectedRoute>
            }
          />
          <Route
            path="/historial-seguimiento"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Seguimiento",
                ]}
              >
                <HistorialSeguimiento />
              </ProtectedRoute>
            }
          />
          <Route
            path="/formularios"
            element={
              <ProtectedRoute
                rol={["Superadministrador", "Seguimiento", "Cumplimiento"]}
              >
                <Formulario />
              </ProtectedRoute>
            }
          />
          <Route path="/oopt" element={<OOPT />} />
          <Route path="/aptis" element={<APTIS />} />
          <Route path="/toefl" element={<TOEFL />} />
          <Route path="/ielts" element={<IELTS />} />
          <Route
            path="/reporte-academico"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <ReporteAcademico />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reporte-estudiantes"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <ReporteEstudiantes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ver-constancia"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Seguimiento",
                  "Administrativo",
                ]}
              >
                <ReporteConstancia />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/biblioteca"
            element={
              <ProtectedRoute rol={["Superadministrador, Marketing, Docente"]}>
                <Padre />
              </ProtectedRoute>
            }
          />
          <Route
            path="/biblioteca/:id"
            element={
              <ProtectedRoute rol={["Superadministrador, Marketing, Docente"]}>
                <Hijo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/config-biblioteca"
            element={
              <ProtectedRoute rol={["Superadministrador, Marketing"]}>
                <ConfigBiblioteca />
              </ProtectedRoute>
            }
          />
          <Route
            path="/review-biblioteca"
            element={
              <ProtectedRoute
                rol={["Superadministrador, Marketing", "Docente"]}
              >
                <ReviewBiblioteca />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reporte-saldo"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <ReporteSaldo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/derechos-grado"
            element={
              <ProtectedRoute
                rol={[
                  "Superadministrador",
                  "Coordinador Académico",
                  "Administrativo, Cumplimiento, Seguimiento",
                ]}
              >
                <DerechoGrado />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </HashRouter>
  )
}

export default App
