import React, {useRef, useState} from "react"
import MyLayout from "../../components/MyLayout"
import {Button, Card, Form, Select, Spin, message, Space, Collapse} from "antd"
import reportesService from "../../services/reportes.service"
import {useEffect} from "react"
import moment from "moment"
import "./Reportes.css"
const {Panel} = Collapse
const XLSX = require("sheetjs-style")

const currentYear = new Date().getFullYear() // Obtiene el año actual
const startYear = 2023 // Año inicial
const years = Array.from(
  {length: currentYear - startYear + 1},
  (_, i) => startYear + i
) // Genera los años

const Reportes = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const ref = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const [data, setData] = useState()
  const [tutorias, setTutorias] = useState()
  const [totalEstudiantes, setTotalEstudiantes] = useState(0)
  const [totalCursos, setTotalCursos] = useState(0)
  const [constancias, setConstancias] = useState()
  const [totalInscripciones, setTotalInscripciones] = useState(0)
  const [totalMatricula, setTotalMatricula] = useState(0)
  const [totalAllPagos, setTotalAllPagos] = useState(0)
  const [totalRetiros, setTotalRetiros] = useState(0)
  // useEffect(()=>{

  // },[])
  // const formatterPeso = new Intl.NumberFormat('es-CO', {
  //   style: 'currency',
  //   currency: 'COP',
  //   minimumFractionDigits: 0
  // })

  const formatterPeso = new Intl.NumberFormat("en-US")

  const loadData = (values) => {
    setLoading(true)
    setData([])

    reportesService
      .cuadre({
        mes: values.mes,
        annio: values.annio,
        ordenar: values.ordenar,
        estado: values.estado,
      })
      .then((res) => {
        console.log("", res.data)
        let contador = 0
        let listaEstudiantes = []
        let contadorCursos = 0
        let totalInscripcion = 0
        let totalMatricula = 0
        let totalAllPagos = 0
        for (let item of res.data[0]) {
          contadorCursos++
          for (let matricula of item.Matriculas) {
            if (matricula.estado == "Retirado") {
              totalRetiros++
            }
            let validar = listaEstudiantes.filter(
              (item) => item == matricula.estudiante_id
            )
            if (validar.length == 0) {
              listaEstudiantes.push(matricula.estudiante_id)
            }
            for (let item of matricula.Obligacions) {
              if (item.inscripcion == "Si") {
                totalInscripcion += item.valor
              } else {
                totalMatricula += item.valor
              }

              if (item.PagoObligacions) {
                for (let pago of item.PagoObligacions) {
                  if (pago.Pago) {
                    if (
                      pago.Pago.estado == "Activo" ||
                      pago.Pago.estado == "Generado"
                    ) {
                      totalAllPagos += pago.valor
                    }
                  }
                }
              }

              if (item.PagoFavorObligacions) {
                for (let pago of item.PagoFavorObligacions) {
                  if (pago.PagoFavor) {
                    if (
                      pago.PagoFavor.estado == "Activo" ||
                      pago.PagoFavor.estado == "Generado"
                    ) {
                      totalAllPagos += pago.valor
                    }
                  }
                }
              }
            }
          }
        }

        setTotalEstudiantes(listaEstudiantes.length)
        setTotalCursos(contadorCursos)
        setTotalInscripciones(totalInscripcion)
        setTotalMatricula(totalMatricula)
        setTotalAllPagos(totalAllPagos)
        console.log(res.data)

        setData(res.data[0])
        setTutorias(res.data[1])
        setConstancias(res.data[2])
        if (res.data.length == 0) {
          message.error("No existen datos")
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error("No existen datos")
      })
  }

  const validarHorario = (horario) => {
    if (horario.length > 0) {
      let cadenaHorario = ""
      let myHorario = horario.sort((a, b) => {
        return parseInt(a.dia) - parseInt(b.dia)
      })
      const dias = ["Domi", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
      let diasFinal = []
      for (let hora of myHorario) {
        let buscar = diasFinal.findIndex((item) => item.dia == hora.dia)

        if (buscar == -1) {
          diasFinal.push({dia: parseInt(hora.dia), diaFinal: dias[hora.dia]})
        }
      }

      for (let item of diasFinal) {
        cadenaHorario += `${item.diaFinal}, `
      }

      cadenaHorario += ` - ${moment(myHorario[0].start).format(
        "hh:mm a"
      )} a ${moment(myHorario[0].end).format("hh:mm a")}`

      return cadenaHorario
    } else {
      return "No hay calendario asignado"
    }
  }

  const convertirFecha = (fecha) => {
    let array = fecha.split("-")
    let myfecha = array[2] + "/" + array[1] + "/" + array[0]
    return myfecha
  }

  const validarTipoObligacion = (obligaciones) => {
    let totalMatricula = 0
    let totalInscripcion = 0
    let totalPagos = 0
    let saldo = 0
    let recibos = ""
    for (let item of obligaciones) {
      if (item.estado != "Anulado") {
        if (item.inscripcion == "Si") {
          totalInscripcion += item.valor
        } else {
          totalMatricula += item.valor
        }

        if (item.PagoObligacions) {
          for (let pago of item.PagoObligacions) {
            if (pago.Pago) {
              if (
                pago.Pago.estado == "Activo" ||
                pago.Pago.estado == "Generado"
              ) {
                totalPagos += pago.valor
                recibos += pago.Pago.codigo_recibo + "-"
              }
            }
          }
        }
      }
    }

    saldo = totalMatricula + totalInscripcion - totalPagos

    return [totalMatricula, totalInscripcion, totalPagos, saldo, recibos]
  }

  const validarTipoObligacionTutoria = (obligaciones) => {
    let totalMatricula = 0
    let totalInscripcion = 0
    let totalPagos = 0
    let saldo = 0
    let recibos = ""
    for (let item of obligaciones) {
      if (item.estado != "Anulado") {
        if (item.inscripcion == "Si") {
          totalInscripcion += item.valor
        } else {
          totalMatricula += item.valor
        }

        if (item.PagoObligacions) {
          for (let pago of item.PagoObligacions) {
            if (pago.Pago) {
              if (
                pago.Pago.estado == "Activo" ||
                pago.Pago.estado == "Generado"
              ) {
                totalPagos += pago.valor
                recibos += pago.Pago.codigo_recibo + "-"
              }
            }
          }
        }
      }
    }

    saldo = totalMatricula + totalInscripcion - totalPagos

    return [totalMatricula, totalInscripcion, totalPagos, saldo, recibos]
  }

  const validarTipoObligacionConstancia = (obligaciones) => {
    let totalMatricula = 0
    let totalInscripcion = 0
    let totalPagos = 0
    let saldo = 0
    let recibos = ""
    for (let item of obligaciones) {
      if (item.estado != "Anulado") {
        if (item.inscripcion == "Si") {
          totalInscripcion += item.valor
        } else {
          totalMatricula += item.valor
        }

        if (item.PagoObligacions) {
          for (let pago of item.PagoObligacions) {
            if (pago.Pago) {
              if (
                pago.Pago.estado == "Activo" ||
                pago.Pago.estado == "Generado"
              ) {
                totalPagos += pago.valor
                recibos += pago.Pago.codigo_recibo + "-"
              }
            }
          }
        }
      }
    }

    saldo = totalMatricula + totalInscripcion - totalPagos

    return [totalMatricula, totalInscripcion, totalPagos, saldo, recibos]
  }

  const formatName = (record) => {
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${
      record.primerApellido
    } ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const cuadre = React.useCallback(async () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.table_to_sheet(ref.current)
    var wscols = []
    for (let i = 0; i < 15; i++) {
      wscols.push({wch: 20})
    }

    // var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    ws["!cols"] = wscols

    let range1 = ["A4", "B1", "B2", "B3", "K4", "O1", "O2"]

    for (let item of range1) {
      ws[item].s = {
        font: {
          sz: 14,
          bold: true,
          color: {rgb: "#FFFFFF"},
        },

        alignment: {
          horizontal: "center",
          vertical: "center",
        },
      }
    }

    var ws2 = XLSX.utils.table_to_sheet(ref2.current)
    var wscols2 = []
    for (let i = 0; i < 15; i++) {
      wscols2.push({wch: 20})
    }

    ws2["!cols"] = wscols2

    var ws3 = XLSX.utils.table_to_sheet(ref3.current)
    var wscols3 = []
    for (let i = 0; i < 15; i++) {
      wscols3.push({wch: 20})
    }

    ws2["!cols"] = wscols3

    XLSX.utils.book_append_sheet(wb, ws, "Reporte Financiero")
    XLSX.utils.book_append_sheet(wb, ws2, "Reporte Tutorias")
    XLSX.utils.book_append_sheet(wb, ws3, "Reporte Constancias")
    XLSX.writeFile(wb, "Reporte-Financiero.xlsx")
  })

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <MyLayout>
      <Card>
        <h4>Reporte Cuadre Financiero</h4>

        <Form
          name="basic"
          layout="vertical"
          labelCol={{span: 18}}
          wrapperCol={{span: 24}}
          autoComplete="off"
          onFinish={loadData}
          form={form}
        >
          <Form.Item
            label="Mes"
            name="mes"
            rules={[{required: true, message: "Seleccione un mes!"}]}
          >
            <Select>
              <Select.Option value="1">Enero</Select.Option>
              <Select.Option value="2">Febrero</Select.Option>
              <Select.Option value="3">Marzo</Select.Option>
              <Select.Option value="4">Abril</Select.Option>
              <Select.Option value="5">Mayo</Select.Option>
              <Select.Option value="6">Junio</Select.Option>
              <Select.Option value="7">Julio</Select.Option>
              <Select.Option value="8">Agosto</Select.Option>
              <Select.Option value="9">Septiembre</Select.Option>
              <Select.Option value="10">Octubre</Select.Option>
              <Select.Option value="11">Noviembre</Select.Option>
              <Select.Option value="12">Diciembre</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Año"
            name="annio"
            rules={[{required: true, message: "Seleccione un año!"}]}
          >
            <Select>
              {years.map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Ordenar por"
            name="ordenar"
            rules={[{required: true, message: "Ordenar por"}]}
          >
            <Select>
              <Select.Option value="jornada">Jornada</Select.Option>
              <Select.Option value="docente_id">Docente</Select.Option>
            </Select>
          </Form.Item>

          {/* <Form.Item label="Filtrar"  name="estado" rules={[{ required: true, message: 'Filtrar' }]}>
                    <Select>
                        <Select.Option value="TODOS">Activos y Finalizados</Select.Option>
                        <Select.Option value="Activos">Solo Activos</Select.Option>
                    </Select>
                </Form.Item> */}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Generar Reporte
            </Button>
            {data && data.length > 0 && (
              <Button onClick={cuadre}>Descargar Reporte Excel</Button>
            )}
          </Form.Item>
        </Form>
      </Card>

      {data && (
        // <table ref={ref} style={{display:"none"}}>

        <Space direction="vertical" className="space">
          <Collapse
            collapsible="header"
            defaultActiveKey={["1"]}
            className="colapsable"
          >
            <Panel header="Reporte Cuadre" key="1">
              <div class="wrapper1">
                <table ref={ref} className="cuadre">
                  <thead>
                    <tr>
                      <td></td>
                      <th colspan="13">CENTRO DE FORMACIÓN EN INGLES</th>
                      <th> CODIGO MAC-FO-03 </th>
                    </tr>
                    <tr>
                      <td></td>
                      <th colspan="13">
                        PROCESO: GESTIÓN MARKETING Y ATENCIÓN AL CLIENTE
                      </th>
                      <th>VERSION 1</th>
                    </tr>
                    <tr>
                      <td></td>
                      <th colspan="13">
                        CONTROL ACADEMICO Y FINANCIERO DE ESTUDIANTES
                      </th>
                      <th>FECHA 5/21/2019</th>
                    </tr>

                    <tr>
                      <th colspan="10">INFORMACION ACADEMICA</th>
                      <th colspan="5">INFORMACIÓN FINANCIERA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Numero de estudiantes</td>
                      <td>Codigo estudiante</td>
                      <td>Codigo de curso</td>
                      <td>Estudiante</td>
                      <td>Ciclo cursado</td>
                      <td>Horario</td>
                      <td>Intesidad Horaria</td>
                      <td>Intesidad Semanal</td>
                      <td>Fecha Inicio</td>
                      <td>Fecha Final</td>
                      <td>Inscripción</td>
                      <td>Factura</td>
                      <td>Abono</td>
                      <td>Saldo</td>
                      <td>Recibos de Pago</td>
                    </tr>

                    {data.map((item) => {
                      return (
                        <>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>{item.id}</td>
                            <td className="profe">
                              PROFESOR: {item.Docente.primerNombre}{" "}
                              {item.Docente.segundoNombre}{" "}
                              {item.Docente.primerApellido}{" "}
                              {item.Docente.segundoApellido}
                            </td>
                            <td className="profe">
                              {item.nivel} - {item.ciclo} Jornada:{" "}
                              {item.jornada}{" "}
                              <span className="estado">{item.estado}</span>
                            </td>
                            <td>{validarHorario(item.Horarios)}</td>
                            <td></td>
                            <td></td>
                            <td>
                              {moment(item.fechaInicial + " 00:00:00").format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>
                              {moment(item.fechaFinal + " 00:00:00").format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            {/* <td>{". " + convertirFecha(item.fechaInicial)}</td>
                <td>{". " + convertirFecha(item.fechaFinal)}</td> */}
                          </tr>

                          {item.Matriculas.map((matricula, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td> {matricula.Estudiante.id}</td>
                                <td></td>
                                <td>{formatName(matricula.Estudiante)}</td>

                                <td>{item.nivel}</td>
                                <td></td>
                                <td>{item.horas}</td>
                                <td>{item.horas / 4}</td>
                                <td>
                                  {moment(matricula.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item.fechaFinal + " 00:00:00").format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>

                                {/* <td>{"."+ moment(item.fechaInicial).format("DD/MM/YYYY")}</td>
                <td>{"."+ + moment(item.fechaFinal).format("DD/MM/YYYY")}</td> */}

                                <td>
                                  {" "}
                                  {formatterPeso.format(
                                    validarTipoObligacion(
                                      matricula.Obligacions
                                    )[1]
                                  )}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {formatterPeso.format(
                                    validarTipoObligacion(
                                      matricula.Obligacions
                                    )[0]
                                  )}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {formatterPeso.format(
                                    validarTipoObligacion(
                                      matricula.Obligacions
                                    )[2]
                                  )}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {formatterPeso.format(
                                    validarTipoObligacion(
                                      matricula.Obligacions
                                    )[3]
                                  )}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {
                                    validarTipoObligacion(
                                      matricula.Obligacions
                                    )[4]
                                  }{" "}
                                </td>
                              </tr>
                            )
                          })}
                        </>
                      )
                    })}

                    <tr>
                      <td>Total Estudiantes {totalEstudiantes}</td>
                      <td></td>
                      <td>Total Cursos {totalCursos}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        Total Inscripciones{" "}
                        {formatterPeso.format(totalInscripciones)}{" "}
                      </td>
                      <td>
                        Total Facturas {formatterPeso.format(totalMatricula)}{" "}
                      </td>
                      <td>
                        Total Pagos {formatterPeso.format(totalAllPagos)}{" "}
                      </td>
                      <td>
                        Saldo{" "}
                        {formatterPeso.format(totalMatricula - totalAllPagos)}{" "}
                      </td>
                    </tr>

                    <tr>
                      <td>Total Retiros {totalRetiros}</td>
                    </tr>
                  </tbody>
                </table>

                <br></br>
                <br></br>
                <h2>Tutorias</h2>
                <table ref={ref2} className="tableTutoria">
                  <tbody>
                    <tr>
                      <td>Numero de estudiantes</td>
                      <td>Codigo estudiante</td>

                      <td>Estudiante</td>
                      <td>Tema</td>
                      <td>Modo</td>
                      <td>Horas</td>
                      <td>Fecha</td>
                      <td>Factura</td>
                      <td>Abono</td>
                      <td>Saldo</td>
                      <td>Recibos de Pago</td>
                    </tr>

                    {tutorias.map((matricula, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td> {matricula.Estudiante.id}</td>

                          <td>{formatName(matricula.Estudiante)}</td>

                          <td>{matricula.tema}</td>
                          <td>{matricula.modo}</td>
                          <td>{matricula.horas}</td>

                          <td>
                            {moment(matricula.fecha + " 00:00:00").format(
                              "DD/MM/YYYY"
                            )}
                          </td>

                          <td>
                            {formatterPeso.format(
                              validarTipoObligacionTutoria(
                                matricula.Obligacions
                              )[0]
                            )}{" "}
                          </td>
                          <td>
                            {formatterPeso.format(
                              validarTipoObligacionTutoria(
                                matricula.Obligacions
                              )[2]
                            )}{" "}
                          </td>
                          <td>
                            {formatterPeso.format(
                              validarTipoObligacionTutoria(
                                matricula.Obligacions
                              )[3]
                            )}{" "}
                          </td>
                          <td>
                            {
                              validarTipoObligacionTutoria(
                                matricula.Obligacions
                              )[4]
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                <br></br>
                <br></br>
                <h2>Constancias</h2>
                <table ref={ref3} className="tableTutoria">
                  <tbody>
                    <tr>
                      <td>Numero de estudiantes</td>
                      <td>Codigo estudiante</td>

                      <td>Estudiante</td>
                      <td>Observaciones</td>
                      <td>Fecha</td>
                      <td>Factura</td>
                      <td>Abono</td>
                      <td>Saldo</td>
                      <td>Recibos de Pago</td>
                    </tr>

                    {constancias.map((matricula, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td> {matricula.Estudiante.id}</td>

                          <td>{formatName(matricula.Estudiante)}</td>

                          <td>{matricula.observaciones}</td>

                          <td>
                            {moment(matricula.fecha + " 00:00:00").format(
                              "DD/MM/YYYY"
                            )}
                          </td>

                          <td>
                            {formatterPeso.format(
                              validarTipoObligacionConstancia(
                                matricula.Obligacions
                              )[0]
                            )}{" "}
                          </td>
                          <td>
                            {formatterPeso.format(
                              validarTipoObligacionConstancia(
                                matricula.Obligacions
                              )[2]
                            )}{" "}
                          </td>
                          <td>
                            {formatterPeso.format(
                              validarTipoObligacionConstancia(
                                matricula.Obligacions
                              )[3]
                            )}{" "}
                          </td>
                          <td>
                            {
                              validarTipoObligacionConstancia(
                                matricula.Obligacions
                              )[4]
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Panel>
          </Collapse>
        </Space>
      )}
    </MyLayout>
  )
}

export default Reportes
