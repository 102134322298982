import http from "../utils/http-common"

class ArchivoCursoService {
  getAll() {
    return http.get("/archivo/curso")
  }
  get(id) {
    return http.get(`/archivo/curso/${id}`)
  }

  getForain(id) {
    return http.get(`/archivo/curso/forain/${id}`)
  }

  create(data) {
    return http.post("/archivo/curso", data)
  }
  update(id, data) {
    return http.put(`/archivo/curso/${id}`, data)
  }
  delete(id, user) {
    return http.delete(`/archivo/curso/${id}/${user}`)
  }

  status(id, data) {
    return http.put(`/archivo/curso/estado/${id}`, data)
  }
}
export default new ArchivoCursoService()
