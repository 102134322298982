import React, {useState, useEffect} from "react"
import {useLocation, Link} from "react-router-dom"
import cursoService from "../../services/curso.service"
import MyLayout from "../../components/MyLayout"
import {
  Breadcrumb,
  Col,
  Row,
  Collapse,
  Space,
  Table,
  Tooltip,
  Modal,
  Button,
  message,
  Spin,
  Avatar,
  Form,
  Input,
} from "antd"
import moment from "moment"
import {
  LogoutOutlined,
  LineChartOutlined,
  CheckCircleOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  SnippetsOutlined,
} from "@ant-design/icons"
import RetiroModal from "../../components/modals/RetiroModal"
import SearchEstudianteModal from "../../components/modals/SearchEstudianteModal"
import matriculaService from "../../services/matricula.service"
import ArchivoCursoModal from "../../components/modals/ArchivoCursoModal"
import {PDFViewer} from "@react-pdf/renderer"
import CursoEstudiantesPDF from "../PDF/CursoEstudiantesPDF"
import {useNavigate} from "react-router-dom"
import SeguimientoActividadModal from "../../components/modals/SeguimientoActividadModal"
import seguimiento_actividadService from "../../services/seguimiento_actividad.service"
import "./VerCursoDocente.css"
import SeguimientoActividadEditModal from "../../components/modals/Edit/SeguimientoActividadEditModal"
import archivo_cursoService from "../../services/archivo_curso.service"
import evaluacionEstudiante from "../../services/evaluacion_estudiante.service"
import {useAuth} from "../../context/auth.context"
import mejoramientoService from "../../services/mejoramiento.service"
import ArchivoCursoEditModal from "../../components/modals/Edit/ArchivoCursoEditModal"
const {TextArea} = Input

const {Panel} = Collapse
const {confirm} = Modal
const VerCursoDocente = () => {
  let location = useLocation()
  let myCurso = location.state.curso
  const [curso, setCurso] = useState(null)
  const [estudiantes, setEstudiantes] = useState([])
  const [archivos, setArchivos] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewHistorialModal, setViewHistorialModal] = useState(false)
  const [matriculaSelected, setMatriculaSelected] = useState()
  const [estudianteSelected, setEstudianteSelected] = useState()
  const [viewEstudianteModal, setViewEstudianteModal] = useState(false)
  const [viewArchivoModal, setViewArchivoModal] = useState(false)
  const [viewPDF, setViewPDF] = useState(false)
  const [viewSeguimientoModal, setViewSeguimientoModal] = useState(false)
  const [seguimientos, setSeguimientos] = useState([])
  const [viewEditSeguimiento, setViewEditSeguimiento] = useState(false)
  const [seguimientoSelected, setSeguimientoSelected] = useState([])
  const [viewMejoramientoModal, setViewMejoramientoModal] = useState(false)
  const [notas, setNotas] = useState([])
  const [formMejoramiento] = Form.useForm()
  const {user, role} = useAuth()
  const [viewArchivoEditModal, setViewArchivoEditModal] = useState(false)
  const [archivoSelected, setArchivoSelected] = useState()
  const navigate = useNavigate()
  useEffect(() => {
    getCurso()
    getSeguimientos()
    getNotas()
  }, [])

  const getNotas = () => {
    evaluacionEstudiante.getForain(myCurso.id).then((res) => {
      setNotas(res.data)
    })
  }

  const getCurso = () => {
    setLoading(true)
    cursoService
      .get(myCurso.id)
      .then((response) => {
        let filterCurso = response.data[0]
        console.log(response.data[0])
        setCurso(filterCurso)
        setEstudiantes(filterCurso.Matriculas)
        let archivos = filterCurso.ArchivoCursos.filter(
          (item) => item.estado != "Eliminado"
        )
        setArchivos(archivos)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const formatName = (record) => {
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${
      record.primerApellido
    } ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const convertirFecha = (fecha) => {
    let array = fecha.split("-")
    let myfecha = array[2] + "/" + array[1] + "/" + array[0]
    return myfecha
  }

  const columnsSeguimiento = [
    {
      title: "Fecha",
      render: (record) => <div>{convertirFecha(record.fecha)}</div>,
    },

    {
      title: "Objetivo",
      render: (record) => <div className="textScroll">{record.objetivo}</div>,
    },

    {
      title: "Habilidad",
      render: (record) => <div className="textScroll">{record.habilidad}</div>,
    },

    {
      title: "Actividades Realizadas",
      render: (record) => (
        <div className="textScroll">{record.actividades}</div>
      ),
    },

    {
      title: "Observaciones",
      render: (record) => (
        <div className="textScroll">{record.observaciones}</div>
      ),
    },

    {
      title: "Fecha Registro",
      render: (record) => (
        <div>{moment(record.createdAt).format("DD-MM-YYYY")}</div>
      ),
    },

    {
      title: "Opciones",
      render: (record) => {
        if (
          role == "Superadministrador" ||
          (role == "Docente" && curso.estado == "Activo")
        )
          return (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  editSeguimiento(record)
                }}
              >
                <EditOutlined />
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  deleteSeguimiento(record)
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          )
      },
    },
  ]

  const editSeguimiento = (record) => {
    setViewEditSeguimiento(true)
    setSeguimientoSelected(record)
  }

  const deleteSeguimiento = (record) => {
    confirm({
      title: "Desea eliminar esta actividad?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        seguimiento_actividadService
          .delete(record.id)
          .then((response) => {
            console.log(response.data)

            getSeguimientos()
            message.success("Actividad Eliminada")
          })
          .catch((e) => {
            console.log(e)
            message.error("Error al eliminar")
          })
      },
    })
  }

  const getSeguimientos = () => {
    console.log("CURSO_ID", myCurso.id)
    seguimiento_actividadService.getForain(myCurso.id).then((res) => {
      setSeguimientos(res.data)
    })
  }

  const viewHistomodal = (record) => {
    setViewHistorialModal(true)
    setMatriculaSelected(record.id)
  }

  const viewMejoramiento = (record) => {
    formMejoramiento.resetFields()
    setViewMejoramientoModal(true)
    setMatriculaSelected(record.id)
    setEstudianteSelected(record)
  }

  const eliminarArchivo = (record) => {
    confirm({
      title: "Desea eliminar la tarifa?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true)
        archivo_cursoService
          .delete(record.id, user.id)
          .then((res) => {
            message.success("Archivo Eliminado")
            getCurso()
            setLoading(false)
          })
          .catch((error) => {
            message.error("Ha ocurrido un error al eliminar")
            setLoading(false)
          })
      },
    })
  }

  const validarSemana = (record) => {
    let semanasActivas = calcularSemanas(
      myCurso.fechaInicial,
      myCurso.fechaFinal
    )
    console.log("Semanas activas", semanasActivas)
    let filtro = semanasActivas.filter(
      (item) =>
        item.nombre == "Semana " + record.semana && item.disabled == false
    )
    console.log(filtro)
    if (filtro.length > 0) {
      return true
    } else {
      return false
    }
  }

  const obtenerMartesDeSemana = (numeroSemana, año) => {
    const fechaMartes = moment().year(año).week(numeroSemana).day(3)
    return fechaMartes.format("YYYY-MM-DD") // Cambia el formato de fecha si lo deseas
  }

  const obtenerViernesDeSemana = (numeroSemana, año) => {
    const fechaMartes = moment().year(año).week(numeroSemana).day(4)
    return fechaMartes.format("YYYY-MM-DD") // Cambia el formato de fecha si lo deseas
  }

  const compararFechas = (fecha1, fecha2) => {
    return fecha1.isBefore(fecha2) // Compara si fecha1 es antes que fecha2
  }

  const calcularSemanas = (fechaInicial, fechaFinal) => {
    let fechaInicialCurso = moment(fechaInicial, "YYYY-MM-DD HH:mm:ss")
    let fechaFinalCurso = moment(fechaFinal, "YYYY-MM-DD 23:59:59")
    let todasFechas = []
    let todasSemanas = []

    while (fechaInicialCurso.isSameOrBefore(fechaFinalCurso)) {
      todasFechas.push(fechaInicialCurso.format("YYYY-MM-DD"))
      fechaInicialCurso.add(1, "days")
    }

    let semanaHoy = moment().week()
    let diaHoy = moment().day()

    for (let item of todasFechas) {
      let fecha = moment(item)
      let numSemana = fecha.week()
      //var numSemana = item.week();
      todasSemanas.push(numSemana)
    }

    const arraySinRepetidos = todasSemanas.filter((elem, index) => {
      return todasSemanas.indexOf(elem) === index
    })

    let data = []

    if (role != "Superadministrador") {
      for (let i = 0; i < arraySinRepetidos.length; i++) {
        const semana = arraySinRepetidos[i]
        let disabled = true

        if (curso.jornada != "Sábados") {
          if (
            semana > semanaHoy ||
            (semana === semanaHoy &&
              compararFechas(
                moment(),
                obtenerMartesDeSemana(semanaHoy + 1, moment().format("YYYY"))
              ))
          ) {
            disabled = false
          }
        } else {
          if (
            semana > semanaHoy ||
            (semana === semanaHoy &&
              compararFechas(
                moment(),
                obtenerViernesDeSemana(semanaHoy + 1, moment().format("YYYY"))
              ))
          ) {
            disabled = false
          }
        }

        data.push({
          id: i + 1,
          nombre: "Semana " + (i + 1),
          semana: semana,
          disabled: disabled,
        })
      }
    } else {
      for (let i = 0; i < arraySinRepetidos.length; i++) {
        const semana = arraySinRepetidos[i]
        data.push({
          id: i + 1,
          nombre: "Semana " + (i + 1),
          semana: semana,
          disabled: false,
        })
      }
    }

    return data
  }

  const columns = [
    {
      title: " ",
      render: (record) => {
        if (record.Estudiante.url != null) {
          return (
            <Avatar
              size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64}}
              src={record.Estudiante.url}
            />
          )
        } else {
          return (
            <Avatar
              size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64}}
              icon={<UserOutlined />}
            />
          )
        }
      },
    },
    {
      title: "Nombres",
      render: (record) => <div>{formatName(record.Estudiante)}</div>,
    },

    {
      title: "No. Identificación",
      render: (record) => <div>{record.Estudiante.numId}</div>,
    },

    {
      title: "Fecha de Matricula",
      render: (record) => (
        <div>{moment(record.createdAt).format("DD-MM-YYYY")}</div>
      ),
    },

    {
      title: "Promedio",
      render: (record) => (
        <div>
          {calcularPromedio(record.Estudiante.id, notas, curso.ParametroCursos)}
        </div>
      ),
    },

    {
      title: "Estado",
      render: (record) => <div>{record.estado}</div>,
    },

    {
      title: "Opciones",
      render: (record) => (
        <>
          <Tooltip title="Crear Plan de Mejoramiento">
            <span
              className="btn_option"
              onClick={() => viewMejoramiento(record)}
            >
              <SnippetsOutlined />
            </span>
          </Tooltip>
        </>
      ),
    },
  ]

  const descargar = (url) => {
    window.open(url)
  }

  const editarSemana = (record) => {
    setViewArchivoEditModal(true)
    setArchivoSelected(record)
  }

  const columnsArchivo = [
    {
      title: "Tipo",
      render: (record) => <div>{record.tipo}</div>,
    },

    {
      title: "Semana",
      render: (record) => <div>Semana {record.semana}</div>,
    },

    {
      title: "Observaciones",
      render: (record) => <div>{record.observaciones}</div>,
    },

    {
      title: "Estado",
      render: (record) => (
        <div
          className={`${record.estado == "Cargado" ? "activo" : ""} ${
            record.estado == "Aprobado" ? "finalizado" : ""
          }  ${record.estado == "Rechazado" ? "retirado" : ""}`}
        >
          <Tooltip title={record.motivo_rechazo}> {record.estado}</Tooltip>
        </div>
      ),
    },

    {
      title: "Descargar Aqui",
      render: (record) => (
        <Button
          onClick={() => {
            descargar(record.url)
          }}
        >
          Descargar{" "}
        </Button>
      ),
    },

    {
      title: "Fecha de subida",

      render: (record) => {
        let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a")
        return <div>{myFecha}</div>
      },
    },

    {
      title: "Opciones",
      render: (record) => {
        if (
          record.estado == "Cargado" &&
          (role == "Docente" || role == "Superadministrador")
        ) {
          return (
            <div>
              {validarSemana(record) && (
                <Button type="primary" onClick={() => editarSemana(record)}>
                  <EditOutlined />
                </Button>
              )}
              <Button type="danger" onClick={() => eliminarArchivo(record)}>
                <DeleteOutlined />
              </Button>
            </div>
          )
        }
        //   if(record.estado == "Cargado") {
        //     botones =  <div><Tooltip title="Aprobar Archivo"><Button type='success' onClick={()=>eliminarArchivo(record)}><CheckCircleOutlined/></Button></Tooltip> <Tooltip title="Rechazar Archivo"><Button type='danger' onClick={()=>eliminarArchivo(record)}><CloseCircleOutlined /></Button></Tooltip></div>
        // }
      },
    },
  ]

  const searchEstudiante = () => {
    setViewEstudianteModal(true)
  }

  const viewLista = () => {
    setViewPDF(true)
  }

  const calcularPromedio = (id, notas, parametros) => {
    console.log(id)
    //console.log(parametros)
    let notasEstudiante = notas.filter((item) => item.estudiante_id == id)
    let totalPromedio = 0
    for (let param of parametros) {
      let totalSub = 0
      let totalParam = 0
      for (let sub of notasEstudiante) {
        if (param.id == sub.parametro_id) {
          totalSub += sub.valor * (sub.SubparametroCurso.porcentaje / 100)
          // console.log(sub.valor * (sub.SubparametroCurso.porcentaje/100))
        }
      }
      totalParam = totalSub * (param.porcentaje / 100)
      totalPromedio += totalParam
    }

    return totalPromedio.toFixed(1)
  }

  const saveMatricula = (form) => {
    setLoading(true)
    matriculaService
      .create(form)
      .then((response) => {
        console.log(response.data)
        setLoading(false)
        setViewEstudianteModal(false)
        getCurso()
        message.success("Guardado Correctamente")
      })
      .catch((e) => {
        setLoading(false)
        message.error("No se ha podido guardar")
        console.log(e)
      })
  }

  const subirArchivo = () => {
    setViewArchivoModal(true)
  }

  const viewModalSeguimiento = () => {
    setViewSeguimientoModal(true)
  }

  const validarHorario = (record) => {
    if (record.horaInicial) {
      let cadenaHorario = ""
      let dias = JSON.parse(record.diasHorario)
      for (let item of dias) {
        cadenaHorario += item.substr(0, 3) + ", "
      }

      cadenaHorario +=
        "- " +
        moment(record.fechaInicial + " " + record.horaInicial).format(
          "hh:mm a"
        ) +
        " a " +
        moment(record.fechaInicial + " " + record.horaFinal).format("hh:mm a")

      return cadenaHorario
    } else {
      return "No hay calendario asignado"
    }
  }

  const viewEvaluaciones = (curso) => {
    navigate("/ver-evaluacion", {state: {curso: curso}})
  }

  const viewAsistencias = (curso) => {
    navigate("/ver-inasistencia", {state: {curso: curso}})
  }

  const saveMejoramiento = (values) => {
    setLoading(true)
    let form = values
    form.docente_id = myCurso.docente_id
    form.curso_id = myCurso.id
    form.estudiante_id = estudianteSelected.estudiante_id
    console.log(form)
    setViewMejoramientoModal(false)
    mejoramientoService
      .create(form)
      .then((res) => {
        console.log(res.data)
        message.success("Mejoramiento Guardado")
        setLoading(false)
      })
      .catch((error) => {
        message.error("No se ha podido guardar")
        setLoading(false)
      })
  }

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <MyLayout>
      <Breadcrumb style={{margin: "16px 0"}}>
        <Breadcrumb.Item>Académico</Breadcrumb.Item>
        <Breadcrumb.Item>Curso </Breadcrumb.Item>
      </Breadcrumb>

      {curso && (
        <>
          <Row>
            <Col span={24}>
              <Row>
                <div className="info">
                  <div className="nombre">
                    {curso.curso} - {curso.nivel} - {curso.ciclo}
                  </div>

                  <div className="numId">
                    {" "}
                    <strong>Docente:</strong> {curso.Docente.primerNombre}{" "}
                    {curso.Docente.primerApellido}
                  </div>
                </div>
              </Row>
              <Row>
                <Col md={24} lg={12} className="columna">
                  <div>
                    <strong>Codigo:</strong> {curso.id}{" "}
                  </div>
                  <div>
                    <strong>Fecha Inicial:</strong>{" "}
                    {moment(curso.fechaInicial).format("DD-MM-YYYY")}{" "}
                  </div>
                  <div>
                    <strong>Periodo:</strong> {curso.periodo}{" "}
                  </div>
                  <div>
                    <strong>Estado:</strong> {curso.estado}{" "}
                  </div>
                  <div>
                    <strong>Horario de clase:</strong> {validarHorario(curso)}
                  </div>
                  <div>
                    <strong>Horas del curso:</strong> {parseInt(myCurso.horas)}{" "}
                  </div>

                  {(curso.modo == "Curso" ||
                    (curso.modo == "Entrenamiento" &&
                      curso.ciclo == "APTIS")) && (
                    <Tooltip title="Ver evaluaciones">
                      <Button
                        onClick={() => {
                          viewEvaluaciones(curso)
                        }}
                        type="primary"
                      >
                        Ver evaluaciones <LineChartOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </Col>

                <Col md={24} lg={12} className="columna">
                  <div>
                    <strong>Programa:</strong> {curso.idioma}
                  </div>
                  <div>
                    <strong>Fecha Final:</strong>{" "}
                    {moment(curso.fechaFinal).format("DD-MM-YYYY")}{" "}
                  </div>
                  <div>
                    <strong>Jornada:</strong> {curso.jornada}{" "}
                  </div>
                  <div>
                    <strong>Fecha Creación:</strong>{" "}
                    {moment(curso.createdAt).format("DD-MM-YYYY")}{" "}
                  </div>
                  <div>
                    {" "}
                    <strong>Modo:</strong> {curso.presentacion}
                  </div>
                  <div
                    className={
                      curso.estado == "Activo"
                        ? "activeCourse"
                        : "finalizeCourse"
                    }
                  >
                    <strong> </strong> {curso.estado}
                  </div>

                  <Tooltip title="Ver inasistencias">
                    <Button
                      onClick={() => {
                        viewAsistencias(curso)
                      }}
                      type="primary"
                    >
                      Ver Inasistencias <CheckCircleOutlined />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Space direction="vertical" className="space">
                <Collapse collapsible="header" defaultActiveKey={["1"]}>
                  <Panel header="Estudiantes" key="1">
                    <Button className="btn_actions" onClick={viewLista}>
                      Lista Estudiantes
                    </Button>

                    {estudiantes && (
                      <Table
                        className="table-responsive"
                        dataSource={estudiantes}
                        columns={columns}
                      ></Table>
                    )}
                  </Panel>

                  <Panel header="Archivos del curso" key="2">
                    {(role == "Superadministrador" || role == "Docente") &&
                      curso.estado == "Activo" && (
                        <Button type="primary" onClick={subirArchivo}>
                          Subir Archivo
                        </Button>
                      )}

                    {archivos && (
                      <Table
                        className="table-responsive"
                        dataSource={archivos}
                        columns={columnsArchivo}
                      ></Table>
                    )}
                  </Panel>

                  <Panel header="Seguimiento de actividades" key="3">
                    {(role == "Superadministrador" || role == "Docente") &&
                      curso.estado == "Activo" && (
                        <Button type="primary" onClick={viewModalSeguimiento}>
                          Crear Seguimiento
                        </Button>
                      )}

                    <Table
                      className="table-responsive"
                      columns={columnsSeguimiento}
                      dataSource={seguimientos}
                    ></Table>
                  </Panel>
                </Collapse>
              </Space>
            </Col>
          </Row>
        </>
      )}
      {matriculaSelected && (
        <RetiroModal
          matricula_id={matriculaSelected}
          updateLoading={setLoading}
          visible={viewHistorialModal}
          modo={myCurso.modo}
          updateVisible={setViewHistorialModal}
          updateCurso={getCurso}
        ></RetiroModal>
      )}

      {viewEstudianteModal && (
        <SearchEstudianteModal
          updateVisible={setViewEstudianteModal}
          saveMatricula={saveMatricula}
          modo={myCurso.modo}
          curso_id={myCurso.id}
          visible={viewEstudianteModal}
        >
          {myCurso}
        </SearchEstudianteModal>
      )}

      {viewArchivoModal && (
        <ArchivoCursoModal
          visible={viewArchivoModal}
          updateLoading={setLoading}
          updateVisible={setViewArchivoModal}
          updateCurso={getCurso}
        >
          {myCurso}
        </ArchivoCursoModal>
      )}

      {viewArchivoEditModal && (
        <ArchivoCursoEditModal
          visible={viewArchivoEditModal}
          dataArchivo={archivoSelected}
          updateLoading={setLoading}
          updateVisible={setViewArchivoEditModal}
          updateCurso={getCurso}
        >
          {myCurso}
        </ArchivoCursoEditModal>
      )}

      {viewPDF && estudiantes && (
        <Modal
          visible={viewPDF}
          width={1080}
          onCancel={() => {
            setViewPDF(false)
          }}
        >
          <PDFViewer style={{width: "100%", height: "90vh"}}>
            <CursoEstudiantesPDF curso={curso}>
              {estudiantes}
            </CursoEstudiantesPDF>
          </PDFViewer>
        </Modal>
      )}

      {viewSeguimientoModal && (
        <SeguimientoActividadModal
          visible={viewSeguimientoModal}
          updateVisible={setViewSeguimientoModal}
          curso_id={curso.id}
          updateLoading={setLoading}
          updateSeguimientos={getSeguimientos}
        ></SeguimientoActividadModal>
      )}

      {viewEditSeguimiento && (
        <SeguimientoActividadEditModal
          visible={viewEditSeguimiento}
          updateVisible={setViewEditSeguimiento}
          updateSeguimientos={getSeguimientos}
        >
          {seguimientoSelected}
        </SeguimientoActividadEditModal>
      )}

      {viewMejoramientoModal && (
        <Modal
          visible={viewMejoramientoModal}
          title="Crear Plan de Mejoramiento"
          onOk={formMejoramiento.submit}
          okText="Guardar"
          onCancel={() => setViewMejoramientoModal(false)}
        >
          <Form
            form={formMejoramiento}
            layout="vertical"
            onFinish={saveMejoramiento}
          >
            <Form.Item
              label="Observación"
              name="observacion"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese Observacion",
                },
              ]}
            >
              <TextArea></TextArea>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </MyLayout>
  )
}

export default VerCursoDocente
