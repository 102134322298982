import React from "react"
import {useLocation} from "react-router-dom"
import "./ReporteConstancia.css"
const ReporteConstancia = () => {
  let location = useLocation()
  let myConstancia = location.state.constancia
  console.log(myConstancia)
  return (
    <>
      {myConstancia.constancia !== null && myConstancia.constancia !== "" && (
        <div className="page ">
          <div>
            <img
              className="encabezado"
              src="./assets/img/encabezadofinal.jpg"
              alt=""
            />
            <div className="textoNegrita textoCentrado">
              <div>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS</div>
              <div>
                INSTITUCIÓN DE EDUCACIÓN PARA EL TRABAJO Y DESARROLLO HUMANO
              </div>
              <div>
                RESOLUCIÓN DE LICENCIA DE FUNCIONAMIENTO Y REGISTRO DE PROGRAMAS
                DE EFICIENCIA EN INGLES
              </div>
              <div>
                No. 1832-27-07-2015 EMANADA DE LA SECRETARIA DE EDUCACIÓN DE
                PASTO- NARIÑO
              </div>
            </div>

            <div>
              <div className="textoCentrado subtitulo">CONSTANCIA</div>

              <div
                className="texto"
                dangerouslySetInnerHTML={{__html: myConstancia.constancia}}
              ></div>

              <div className="firmasello">
                <div
                  className="firmaPaola"
                  style={{marginTop: "100px", borderTop: "1px solid #000"}}
                >
                  {/* <img src="./assets/img/firmapaola.png" alt="" /> */}
                  <div>PAOLA ANDREA NARVAEZ BENAVIDES</div>
                  <div>
                    <strong>SECRETARIA ACADEMICA</strong>
                  </div>
                </div>

                <div className="sello">
                  <img src="./assets/img/sello.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <img
            className="footer-image"
            src="./assets/img/pie-constancia.png"
            alt=""
          />
        </div>
      )}

      <div class="pagebreak"> </div>

      <div className="page">
        <div>
          <img
            className="encabezado"
            src="./assets/img/encabezadofinal.jpg"
            alt=""
          />
          <div className="textoNegrita textoCentrado">
            <div>
              RESOLUCIÓN DE LICENCIA DE FUNCIONAMIENTO Y REGISTRO DE PROGRAMAS
              DE EFICIENCIA EN INGLES
            </div>
            <div>
              No. 1832-27-07-2015 EMANADA DE LA SECRETARIA DE EDUCACIÓN DE
              PASTO- NARIÑO
            </div>
          </div>

          <div className="textoCentrado subtitulo">
            Expide el siguiente certificado de formación académica a:
          </div>

          <div
            className="texto"
            dangerouslySetInnerHTML={{__html: myConstancia.certificado}}
          ></div>

          <div className="firmasello">
            <div
              className="firmaPaola"
              style={{marginTop: "100px", borderTop: "1px solid #000"}}
            >
              {/* <img src="./assets/img/firmapaola.png" alt="" /> */}
              <div>PAOLA ANDREA NARVAEZ BENAVIDES</div>
              <div>
                <strong>SECRETARIA ACADEMICA</strong>
              </div>
            </div>

            <div className="sello">
              <img src="./assets/img/sello.png" alt="" />
            </div>
          </div>

          <img
            className="footer-image"
            src="./assets/img/pie-constancia.png"
            alt=""
          />
        </div>

        {/* <img
          className="footer-image"
          src="./assets/img/pie-constancia.png"
          alt=""
        /> */}
      </div>
    </>
  )
}

export default ReporteConstancia
