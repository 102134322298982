import React from "react"
import {Modal, Table, Input, Form, message, Row, Col, Alert} from "antd"
import {Content} from "antd/lib/layout/layout"
import {useEffect} from "react"
import matriculaService from "../../services/matricula.service"
import matriculaExamenService from "../../services/matricula_examen.service"
import obligacionesService from "../../services/obligaciones.service"
import {useAuth} from "../../context/auth.context"
import {useState} from "react"
import {NumericFormat} from "react-number-format"

const {TextArea} = Input
const RetiroModal = (props) => {
  console.log(props)
  const [form] = Form.useForm()
  const {user} = useAuth()
  const matricula_id = props.matricula_id
  const lugar = props.lugar
  const modo = props.modo
  const [historial, setHistorial] = useState([])
  const [loading, setLoading] = useState(false)
  const [obligaciones, setObligaciones] = useState([])
  const onFinish = (values) => {
    props.updateLoading(true)
    const form = values
    form.matricula_id = matricula_id
    form.usuario_id_retiro = user.id
    if (modo == "Curso" || modo == "Entrenamiento") {
      matriculaService
        .retiro(form)
        .then((response) => {
          console.log(response.data)
          props.updateLoading(false)
          props.updateVisible(false)
          if (lugar == "Estudiante") {
            props.updateEstudiante()
          } else {
            props.updateCurso()
          }

          if (obligaciones.length > 0) {
            props.modalRedirigir()
          }

          message.success("Retirado Correctamente")
        })
        .catch((e) => {
          props.updateLoading(false)
          message.error("No se ha podido guardar")
          console.log(e)
        })
    } else {
      matriculaExamenService
        .retiro(form)
        .then((response) => {
          console.log(response.data)
          props.updateLoading(false)
          props.updateVisible(false)

          props.updateExamen()
          message.success("Retirado Correctamente")
        })
        .catch((e) => {
          props.updateLoading(false)
          message.error("No se ha podido guardar")
          console.log(e)
        })
    }
  }

  const handleCancel = () => {
    props.updateVisible(false)
  }

  useEffect(() => {
    getObligaciones()
  }, [])

  const getObligaciones = () => {
    setLoading(true)
    obligacionesService
      .getByMatricula(matricula_id)
      .then((response) => {
        console.log(matricula_id)
        console.log(response.data)
        let filtro = response.data.filter(
          (item) => item.saldo > 0 && item.rta.relacion_id == matricula_id
        )

        setObligaciones(filtro)

        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  return (
    <Modal
      title="Retiro de Estudiante"
      visible={props.visible}
      onOk={form.submit}
      onCancel={handleCancel}
    >
      <Content>
        <div>
          {obligaciones.map((item) => {
            return (
              <div
                style={{
                  backgroundColor: "#fffbe6",
                  padding: "10px",
                  marginBottom: "5px",
                  border: "1px solid #ffe58f",
                }}
              >
                El estudiante tiene un saldo pendiente por ${" "}
                <NumericFormat
                  value={item.saldo}
                  displayType="text"
                  allowLeadingZeros
                  thousandSeparator=","
                />
              </div>
            )
          })}
        </div>

        <Form
          name="basic"
          layout="vertical"
          labelCol={{span: 18}}
          wrapperCol={{span: 24}}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Fecha de Retiro: "
                name="fechaRetiro"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha de retiro",
                  },
                ]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Comentario: "
                name="comentario"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Comentario",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  )
}

export default RetiroModal
